<template>
    <div>
        <div>
            <div class="exa-head">
                <div class="title"><i class="el-icon-edit-outline" style="color: #1ea0fa;"></i> 新建绩效考核</div>
                <div class="back"><el-button style="color: #1ea0fa;" @click="$router.go(-1)">返回</el-button></div>
            </div>
            <div class="exa-content">
                <el-divider>完善发布信息</el-divider>
                <el-form label-width="200px">
                    <el-form-item label="考评时间">
                        <el-date-picker v-model="Assessment_Time" size="medium" value-format="yyyy-MM" type="month"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="考核开始时间">
                        <el-date-picker v-model="Answer_Start_Time" size="medium" value-format="yyyy-MM-dd" type="date"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="考核结束时间">
                        <el-date-picker v-model="Answer_End_Time" size="medium" value-format="yyyy-MM-dd" type="date"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="考核方式">
                        <!-- <el-checkbox v-model="checked">需要老师自评</el-checkbox> -->
                        <el-radio v-model="AssessmentType" :label="1">自评+考核评</el-radio>
                        <el-radio v-model="AssessmentType" :label="2">仅自评</el-radio>
                        <el-radio v-model="AssessmentType" :label="3">仅考核评</el-radio>
                    </el-form-item>
                    <el-form-item label="考核组成员">
                        <el-select :disabled="AssessmentType==2" v-model="kkteachers" multiple filterable placeholder="请选择">
                            <el-option v-for="item in teachers" :key="item.ID" :label="item.NAME"
                                :value="item.ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评价/自评对象">
                        <!-- <el-tree
								:data="treeTeachers"
								:props="menuProps"
								ref="treeRef"
								node-key="ID"
								:default-expand-all="false"
								:default-checked-keys="selTeachers"
								show-checkbox
								class="menu-data-tree"
							/> -->
                        <el-cascader v-model="selTeachers" :options="treeTeachers" :props="props" collapse-tags
                            clearable filterable></el-cascader>
                    </el-form-item>

                    <el-form-item label="">
                        <el-button type="primary" style="width: 40%;" @click="submit">完成</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog title="添加老师" :visible.sync="visible" :close-on-click-modal="false" width="30%" :before-close="close">
            <div class="">
                <div class="chanTea">
                    <div>已选老师：</div>
                    <div class="itm" v-for="(val, ind) in changeTeacher" :key="ind">{{ val.NAME }}<i
                            class="el-icon-close" @click="delTea(ind)"></i></div>
                </div>
                <div>
                    <el-input v-model="search" placeholder="请输入" style="margin-bottom: 10px;">
                        <el-button slot="append" icon="el-icon-search" @click="searchKey"></el-button>
                    </el-input>
                    <el-table ref="multipleTable" :data="kkteachers" tooltip-effect="dark" style="width: 100%" border
                        @select="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="NAME" label="姓名">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="btns">
                    <el-button @click="close">取消</el-button>
                    <el-button type="primary" @click="sure">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            Assessment_Time: '',
            Answer_Start_Time: '',
            Answer_End_Time: '',

            //自评教师Id
            comment: [],

            checked: true,
            AssessmentType: 1,
            mould: {},
            teachers: [],

            kkteachers: [],

            //树操作，选中的用户数据
            selTeachers: [],
            treeTeachers: [],
            menuProps: {
                children: 'children',
                label: 'label',
            },

            changeTeacher: [],

            visible: false,

            props: { multiple: true },
            options: [],
            search: ''
        }
    },
    mounted() {
        this.getTeachers()
        if (this.$route.query.id) {
            // this.getMould()
        } else {
            this.mould = JSON.parse(sessionStorage.getItem('jxInfo'))
            console.log('mould', this.mould)
        }
    },
    methods: {
        getMould() {
            let self = this
            console.log(self.$route.query.id)
            this.whale.remote.getResult({
                url: '/api/School/PFM/PfmTempalteSetting/Get?Id=' + self.$route.query.id,
                completed(its) {
                    console.log(its)
                    self.mould = its.DATA
                }
            })
        },
        getTeachers() {
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/School/ORG/TeacherApi/GetList",
                data: { KEY: self.search },
                completed: function (its) {

                    console.log('its', its)
                    let t = {}
                    its.map(o => {
                        if (t[o.DEPT_NAME]) t[o.DEPT_NAME].push({
                            label: o.NAME,
                            value: o.ID,
                            id: o.ID
                        })
                        else t[o.DEPT_NAME] = [{
                            label: o.NAME,
                            value: o.ID,
                            id: o.ID
                        }]
                    });

                    let arr = [];
                    for (let o in t) {
                        arr.push({
                            label: o,
                            value: o,
                            children: t[o]
                        })
                    }
                    console.log('arr', arr)
                    self.treeTeachers = arr;

                    self.teachers = its;
                    self.options = its

                    if (self.changeTeacher.length) {
                        self.showSelect()
                    }
                }
            })
        },
        show() {
            this.visible = true
            this.kkteachers = this.teachers
        },
        delTea(i) {
            this.$refs.multipleTable.toggleRowSelection(this.changeTeacher[i], false)
            this.changeTeacher.splice(i, 1)
        },
        sure() {
            if (this.changeTeacher.length) {
                this.visible = false
            } else {
                this.$message.warning('请选择考核教师')
            }
        },
        close() {
            this.visible = false
            this.changeTeacher = []
            this.$refs.multipleTable.clearSelection()
        },
        searchKey() {
            console.log('545', this.changeTeacher)
            if (this.search == '') {
                this.kkteachers = this.options
            } else {
                this.kkteachers = this.teachers.filter(item => item.NAME.indexOf(this.search) >= 0)
            }
            // let self = this
            // this.changeTeacher.map(val => {
            //     console.log(val)
            //     self.$refs.multipleTable.toggleRowSelection(val, true)
            // })
        },
        handleSelectionChange(val) {
            console.log(val)
            this.changeTeacher = val
        },
        submit() {
            //获取选中的教师
            // var selTeacherList = this.$refs.treeRef.getCheckedNodes();
            // var selTeacherIds=selTeacherList.map(item => item.id).filter(item=>!!item);
            var selTeacherList = []
            this.selTeachers.forEach(item => {
                selTeacherList.push(item[1])
            })
            this.comment = selTeacherList.filter(item => !!item);
            this.changeTeacher = this.kkteachers;

            if (this.comment.length == this.teachers.length)
                this.comment = ['All']
            else
                this.comment = selTeacherList

            if (this.Assessment_Time == '') {
                this.$message.warning('请选择考评时间')
                return
            }
            if (this.Answer_Start_Time == '') {
                this.$message.warning('请选择开始时间')
                return
            }
            if (this.AssessmentType == '') {
                this.$message.warning('请选择结束时间')
                return
            }

            if ((this.AssessmentType == 1 || this.AssessmentType == 3) && this.changeTeacher.length == 0) {
                this.$message.warning('请选择考核人')
                return
            }
            if ((this.AssessmentType == 1 || this.AssessmentType == 2) && this.comment.length == 0) {
                this.$message.warning('请选择评价/自评对象')
                return
            }

            let ids = []
            this.changeTeacher.map(i => {
                ids.push(i)
            })
            let data = {
                Assessment_Name: this.mould.Template_Name,
                Answer_Start_Time: this.Answer_Start_Time,
                Answer_End_Time: this.Answer_End_Time,
                Assessment_Time: this.Assessment_Time,

                Man_UserIds: ids.toString(),
                Assessment_Description: this.mould.Assessment_Description,

                AssessmentType: this.AssessmentType,
                Self_Assessment_Users: this.comment.toString(),
                Details: this.mould.Details,
            }
            console.log(data)
            let self = this
            this.whale.remote.getResult({
                url: 'api/School/PFM/PfmAssessmentSetting/CreateNew',
                data: data,
                completed(its) {
                    console.log('its', its)
                    self.$message.success('创建成功')
                    sessionStorage.removeItem('jxInfo')
                    self.$router.push('/perform/exa')
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.exa-head {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.exa-content {
    background: #fff;
    width: 800px;
    margin: 0 auto;
    padding: 30px 0;
}

.chanTea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .itm {
        background: #eef5fc;
        border-radius: 50px;
        padding: 4px 15px;
        margin: 0 5px;
    }
}

.btns {
    text-align: center;
    margin-top: 10px;
}
</style>